import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import MatelasCarousel from "../MatelasCarousel"

const MatelasImageSliderWrapper = styled.div`
  position: relative;
  display: ${({ mobile_display }) => (mobile_display ? "none" : "block")};

  @media (max-width: 600px) {
    display: block;
  }
`

export const MatelasImageSlider = ({
  intl,
  data,
  newTag,
  page,
  cartbundle,
  mobile_display,
}) => (
  <MatelasImageSliderWrapper mobile_display={mobile_display}>
    {data && data.length > 0 && (
      <MatelasCarousel
        data={data}
        newTag={newTag}
        page={page}
        cartbundle={cartbundle}
      />
    )}
  </MatelasImageSliderWrapper>
)

export default injectIntl(MatelasImageSlider)
